.popup-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup container */
.popup-form-container {
  display: flex;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  max-width: 800px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Left section of the popup */
.popup-form-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #010E33;
  color: white;
  padding: 20px;
  width: 40%;
}

.popup-form-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.popup-form-highlight {
  font-size: 1rem;
  font-weight: bold;
  color: #ffcc00;
}

.popup-form-stats {
  margin-top: 10px;
  font-size: 0.9rem;
}

.popup-form-image-container {
  margin-top: 20px;
  text-align: center;
}

.popup-form-image {
  max-width: 100%;
  height: auto;
}

/* Right section of the popup */
.popup-form-right {
  width: auto;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .popup-form-left {
    display: none;
  }

  .popup-form-container {
    border-radius: 0;
  }

  .popup-form-right {
    width: 83%; 
    height: auto;
    padding: 20px;
  }
}
.popup-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-form-header-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.popup-close-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: gray;
}

.popup-close-btn:hover {
  color: black;
}

/* Form styles */
.popup-form {
  display: flex;
  flex-direction: column;
}

.popup-form-input-group {
  margin-bottom: 15px;
}

.popup-form-input,
.popup-form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.popup-form-input:focus,
.popup-form-select:focus {
  outline: none;
  border-color: #02adef;
  box-shadow: 0 0 3px #02adef;
}

.popup-form-error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.popup-form-submit {
  background-color: #010E33;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.popup-form-submit:hover {
  background-color: #0086c2;
}