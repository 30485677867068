body {
    margin: 0;
    padding-bottom: 100px; /* Increase if necessary to avoid overlap with fixed footer */
    min-height: 100vh; /* Ensure full viewport height */
    display: flex;
    flex-direction: column;
    overflow-x:hidden;
  }
  
  main {
    flex: 1; /* Ensures content area grows to fill available space */
  }
  