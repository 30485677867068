/* Basic Reset */
body,
ul,
li,
a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

:root {
  --navbar-text-color: white;
  --navbar-bg-color: linear-gradient(to right,#011747, #00041d,#011747,#000000);
}

.logo-name a {
  color: var(--navbar-text-color);
  text-decoration: none;
  font-family: "72 Brand Variable", Arial, Helvetica, sans-serif;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 20;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-family: "72 Brand Variable", Arial, Helvetica, sans-serif;
  background: var(--navbar-bg-color);
  color: var(--navbar-text-color);
  transition: background 0.3s, color 0.3s;
}

.navbar.scrolled {
  background: rgba(255, 255, 255, 0.2);
  --navbar-text-color: black;
  backdrop-filter: blur(10px);
}

.logo-name {
  color: inherit;
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 30px;
  font-size: 1rem;
  font-weight: 500;
}

.nav-links li {
  position: relative;
}

.nav-links li a {
  color: inherit;
  font-size: 15px;
  transition: color 0.3s;
  font-weight: bold;
}

.nav-links li a:hover {
  color: #f0a500;
}

.toggle-button {
  display: none;
  /* Default to hidden */
}

/* Dropdown Menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  /* border-radius: 8px; */
  /* padding: 10px 0; */
  overflow-y: auto;
}

#li li {
  width: 6rem;
  height: .5rem;
  text-align: start;
  border: rgb(95, 95, 95) 1px solid;
}

#li li a {
  display: flex;
  font-size: .7rem;
}

.nav-links li:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 10px 20px;
}

.dropdown-menu li a {
  color: #fff;
  font-size: 15px;
  transition: color 0.3s;
}

.dropdown-menu li a:hover {
  color: #f0a500;
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    /* Hide links on small screens initially */
  }

  .toggle-button {
    display: block;
    background: none;
    border: none;
    color: inherit;
    font-size: 28px;
    cursor: pointer;
  }

  #li {
    border: 1px solid rgb(97, 97, 97);
  }

  #li li {
    width: 100%;
    border: none;
  }

  #li li a {
    font-size: 0.8rem;
  }

  .logo-name {
    font-size: 1.2rem;
  }

  .nav-links.active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 3.5rem;
    right: 0.5rem;
    background-color: #333;
    padding: 10px 20px;
    border-radius: 8px;
  }

  .nav-links li a {
    color: #fff;
    font-size: 17px;
    transition: color 0.3s;
  }

  .nav-links li {
    padding: 0.5rem;
  }

  span {
    margin-left: -0.5rem;
    text-shadow: 1px 2px rgba(175, 151, 151, 0.711);
  }

  /* Dropdown Menu */
  .dropdown-menu {
    position: static;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }

  .dropdown-menu li {
    padding: 0.5rem;
  }
}