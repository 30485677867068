/* Existing styles */
.about-container {
  font-family: Arial, sans-serif;
  color: #333;
  margin-bottom: 80%;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
}

.image-section {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  margin-top: -5rem;

}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  /* Start as hidden */
  animation: fadeIn 2s ease-in forwards;
  /* Apply the fade-in animation */
}

.overlay-text h1 {
  margin: 0;
  font-size: 2.5em;
}

.overlay-text p {
  margin: 0;
  font-size: 1.2em;
}

.info-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #B8D1E3;
  flex-wrap: wrap;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  text-align: center;
  animation: bounceIn 1s ease-out;
  /* Apply the bounce-in animation */
}

.info-icon {
  font-size: 2em;
  margin-bottom: 10px;
  animation: iconPulse 1.5s infinite;
  /* Apply the pulse animation */
}

.info-item p {
  margin: 0;
  font-size: 1.1em;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Keyframes for bounce-in animation */
@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Keyframes for pulse animation */
@keyframes iconPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

/* Styles for the new card section */
.card-section {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #fff;
  flex-wrap: wrap;
}

.card {
  width: 250px;
  /* Fixed width */
  height: 250px;
  /* Adjusted height */
  background-color: #B8D1E3;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 10px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: relative;
  /* Ensures proper icon positioning */
}

/* Add some spacing between the icon and the text */
.card-icon {
  font-size: 3em;
  /* Larger size for visibility */
  margin-bottom: 10px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .card {
    width: 200px;
    /* Adjust width for medium-sized screens */
    height: 220px;
    /* Adjust height for medium-sized screens */
  }
}

@media (max-width: 480px) {
  .card {
    width: 180px;
    /* Adjust width for smaller screens */
    height: 200px;
    /* Adjust height for smaller screens */
  }
  .image-section {
    
    height: 400px;
  
  
  }
  .overlay-text {

    padding: 6px;
   
    /* Apply the fade-in animation */
  }
}