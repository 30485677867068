.scrolling-list-container {
  width: 100%;
  overflow: hidden; /* Hide overflow to create scrolling effect */
  background: #f4f4f4; /* Background color */
  padding: 20px 0; /* Top and bottom padding */
}

.scrolling-list {
  display: flex;
  flex-direction: row;
  white-space: nowrap; /* Ensure items are in a single row */
  animation: scroll 30s linear infinite; /* Animation for continuous scrolling */
}

.scrolling-item {
  font-weight: bold;
  font-size: 2.5em; /* Increased font size */
  color: #333;
  padding: 0 10px; /* Horizontal padding for spacing */
  position: relative; /* Position for pseudo-element */
  display: flex;
  align-items: center;
}

.scrolling-item::after {
  content: '•'; /* Unicode for a bullet point */
  color: rgb(76, 108, 175); /* Blue color for the dot */
  font-size: 2em; /* Size of the dot */
  margin-left: 15px; /* Space between text and dot */
}

.scrolling-item:last-child::after {
  content: ''; /* Remove dot from the last item to prevent extra space */
}

@keyframes scroll {
  0% {
    transform: translateX(100%); /* Start from the right edge */
  }
  100% {
    transform: translateX(-100%); /* Move to the left edge */
  }
}

@media (max-width: 768px) {
  .scrolling-item {
    font-size: 1.8em; /* Smaller font size for tablets and small devices */
  }

  .scrolling-item::after {
    font-size: 1.8em; /* Smaller dot size for smaller screens */
    margin-left: 10px; /* Reduce space between text and dot */
  }
}

@media (max-width: 480px) {

  .scrolling-list-container {
    overflow-x: hidden;
    width: 100%;
    overflow-x: hidden; /* Hide overflow to create scrolling effect */
    background: #f4f4f4; /* Background color */
    padding: 20px 0; /* Top and bottom padding */
  }
  
  .scrolling-list {
    animation: scroll 25s linear infinite; /* Adjusted animation speed for smaller screens */
  }
  .scrolling-item {
    font-size: 1.5em; /* Further reduction for mobile screens */
  }

  .scrolling-item::after {
    font-size: 1.5em; /* Further reduction for mobile screens */
    margin-left: 8px; /* Further reduce space between text and dot */
  }
}