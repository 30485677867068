.fixed-background {
  background: url('../assets/Why.webp') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  /* Ensure it covers the viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.choose-us-section {
  margin-top: 40px;
  background: rgba(255, 255, 255, 0.3);
  /* Slightly transparent background for readability */
  width: 80%;
  max-width: 1200px;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.sap-did-you-know-container {
  background: transparent;
  /* Slightly transparent background for readability */
  position: relative;
}

/* Styles for WhyChooseUs Component */
.choose-us-heading {
  text-align: center;
}

.choose-us-heading h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.choose-us-heading p {
  font-size: 1.2em;
  color:whitesmoke;
  font-weight: 600;
}

.choose-us-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2rem;
  justify-content: center;
}

.choose-us-card {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: calc(50% - 20px);
  box-sizing: border-box;
  transition: transform 0.3s, box-shadow 0.3s;
}

.choose-us-card2{
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  margin-left: 25%;
  color: black;
  width: calc(50% - 20px);
  box-sizing: border-box;
  transition: transform 0.3s, box-shadow 0.3s;
}

.choose-us-card h3 {
  margin-top: 0;
  color: black;
}

.choose-us-card p {
  color: #000;
}

.choose-us-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .choose-us-card {
    width: 100%;
    /* Full width on small screens */
  }
}

/* Styles for SAPDidYouKnow Component */
.sap-did-you-know-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sap-image {
  width: 100%;
  max-width: 400px;
  margin-bottom: 0;
}

.sap-facts-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.sap-fact-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.sap-fact-item {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: 600;
  transition: background-color 0.3s;
  cursor: pointer;
}

.sap-fact-item.clicked {
  background-color: #f4f4f4;
}

.sap-fact-text {
  margin: 0;
  font-size: 1em;
}