body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

#splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
  background: linear-gradient(270deg, #002f75, hsl(0, 0%, 0%), #02504c, #000000);
  background-size: 800% 800%; /* Create a large background to allow smooth animation */
  animation: colorShift 10s ease infinite, fadeOut 1s forwards 3s; /* Color shift and fade out */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.splash-text {
  font-size: 2em;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

#text2 {
  animation-delay: 1s; /* Delay for the second text */
}

#text3 {
  animation-delay: 2s; /* Delay for the third text */
}

#main-content {
  display: none; /* Hide main content initially */
  opacity: 0;
  height: 100vh;
  overflow: auto; /* Allow scrolling when content is shown */
}

.show-content {
  display: block; /* Show the content when splash is hidden */
  animation: slideIn 1s forwards;
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

@keyframes fadeOut {
  to {
      opacity: 0;
  }
}

@keyframes slideIn {
  from {
      transform: translateY(100%);
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes colorShift {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  #splash-screen {
      padding: 10px; /* Add padding for smaller screens */
      text-align: center; /* Center-align the text */
  }

  .splash-text {
      font-size: 3rem; /* Adjust font size for smaller screens */
      line-height: 1; /* Adjust line-height for better readability */
  }

  #text2, #text3 {
      font-size: 3rem; /* Adjust font size for smaller text elements */
  }

  #main-content {
      height: auto; /* Allow content height to adapt */
      overflow: visible; /* Ensure all content is visible */
  }
}
