.counter-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  margin-bottom: 80%;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
}

.background-image {
  position: relative;
  width: 100%;
  /* Adjust width as needed */
  height: 100%;
  background-image: url('../assets/v1.webp');
  /* Replace with your image URL */
  background-size: cover;
  background-position: center;
}

.content-overlay {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.counter-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(to right, #007bff, #00c6ff);
  /* Blue gradient */
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 0;
}

.counter {
  text-align: center;
  color: white;
}

.counter span {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .counter-component {
    margin-bottom: 70%;
  }
  .background-image {
    width: 100vw;
    /* Adjust width as needed */
    height: 100vh;
    background-image: url('../assets/V.webp'); /* Image for responsive mode */
    background-size: cover; /* Ensure the image covers the container */
    background-position: center; /* Center the image */
  }

  .counter-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: linear-gradient(to right, #002e5f, #042a7a);
    /* Blue gradient */
    position: absolute;
    bottom: 0;

  }

  .counter {
    font-size: 1rem;
  }

  .content-overlay {
    left: 50%;
    width: 90%;
    /* Adjust width as needed */
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .counter-row {
    flex-wrap: wrap;
    /* Allow wrapping */
    justify-content: center;
    /* Center counters horizontally */
    padding: 10px 0;
  }

  .counter {
    flex: 1 1 50%;
    /* Two counters per row */
    max-width: 50%;
    /* Ensure two counters per row */
    margin-bottom: 15px;
    /* Space between rows */
  }

  .counter span {
    font-size: 1.2rem;
  }
}