swiper-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 83.33%; /* Equivalent to Tailwind's w-5/6 */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to Tailwind's shadow */
    z-index: -1;
  }
  
  /* Center the image within each SwiperSlide */
  .swiper-slide img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: auto;
  }