@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

*{
  margin: 0;
  padding: 0;
}
 
.home{
  margin-bottom: 60%;
}