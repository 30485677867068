.contact{
  background-color: #EFEAE3;
  margin-bottom: 75%;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
  padding: 2rem;
}

.contact-page {
    background-image: url('../assets/contact.webp'); /* Background image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding: 20px;
    margin-top: -5rem;
  }
  
  .contact-content {
    background-color: rgba(255, 255, 255, 0.8); /* White background with opacity for text */
    border-radius: 8px;
    padding: 30px;
    max-width: 600px;
    margin-bottom: 40px;
    margin-top: 20px; /* Add top margin for spacing */
  }
  
  .contact-text h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-text p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .main-card {
    width: 96%;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto; /* Center the contact details */
    width: 100%;
  }
  
  .contact-item {
    flex: 1 1 18%; /* Default width for larger screens */
    min-width: 150px; /* Minimum width to prevent squishing */
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    margin: 10px;
    transition: transform 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-item:hover {
    transform: translateY(-10px);
  }
  
  .icon-container {
    display: inline-block;
    perspective: 1000px; /* Perspective for 3D effect */
  }
  
  .contact-icon {
    font-size: 2rem;
    color: #007bff;
    transition: transform 0.5s;
  }
  
  .icon-container:hover .contact-icon {
    transform: rotateY(180deg); /* Flipping effect */
  }
  
  .contact-item h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
  }
  
  .contact-item p {
    font-size: 1rem;
    color: #555;
  }
  .form-section {
  background-color: rgba(255, 255, 255, 0.9); /* Light background for the form */
  padding: 40px;
  border-radius: 8px;
  margin: 40px auto;
  max-width: 800px; /* Maximum width for the form section */
  text-align: center;
}

.form-section h2 {
  font-size: 2rem; /* Font size for the heading */
  margin-bottom: 15px;
  color: #333;
}

.form-section p {
  font-size: 1.2rem; /* Font size for the subheading */
  margin-bottom: 25px;
  color: #555;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between form elements */
  max-width: 100%; /* Full width within its container */
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%; /* Full width of the container */
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem; /* Font size for input fields */
}

.form-group textarea {
  resize: vertical; /* Allow vertical resizing */
}

.submit-button {
  background-color: #007bff; /* Primary color */
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 1rem; /* Font size for button */
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
.form-section {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;
    border-radius: 8px;
    margin-top: 40px;
    text-align: center;
  }
  
  .form-section h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .form-section p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  .form-group textarea {
    resize: vertical; /* Allow vertical resizing */
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .contact-page {
      background-image: url('../assets/contact.webp'); /* Background image */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
    }
  
    .contact-content {
      max-width: 100%;
      position: relative;
      top: 20px;
      left: 3px; /* Allow content to use full width */
    }
  
    .contact-details {
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center-align contact items */
    }
  
    .contact-item {
      width: 100%; /* Full width for each contact item */
      margin-bottom: 20px; /* Add margin for spacing */
      max-width: 300px; /* Limit max width for smaller devices */
    }
    .form-section {
        padding: 30px;
        position: relative;
        right:10px ;
      }
    
      .form-section h2 {
        font-size: 1.8rem; /* Smaller font size for heading */
      }
    
      .form-section p {
        font-size: 1.1rem; /* Smaller font size for subheading */
      }
    
      .contact-form {
        padding: 10px; /* Reduce padding */
      }
    
      .form-group input,
      .form-group textarea {
        font-size: 0.9rem; /* Smaller font size for inputs */
      }
    
      .submit-button {
        font-size: 0.9rem;
        padding: 10px 18px; /* Adjust padding for smaller screens */
      }
  }
  
  @media (max-width: 480px) {
    .contact-text h1 {
      font-size: 1.8rem; /* Smaller font size for heading */
    }
  
    .contact-text p {
      font-size: 1rem; /* Smaller font size for paragraph */
    }
  
    .contact-item h3 {
      font-size: 1rem; /* Adjust heading size */
    }
  
    .contact-item p {
      font-size: 0.9rem; /* Adjust paragraph size */
    }
  
    .contact-item {
      padding: 15px; /* Reduce padding */
      margin-bottom: 15px; /* Adjust spacing */
      max-width: 50%; /* Further reduce width for very small screens */
    }
   
    .icon-container {
      margin-bottom: 10px; /* Add spacing below icons */
    }
    .form-section {
        padding: 10px;
        margin: 20px auto;
        max-width: 300px; /* Maximum width for the form section */
        text-align: center;
      }
    
      .form-section h2 {
        font-size: 1.5rem; /* Smaller font size for heading */
      }
    
      .form-section p {
        font-size: 1rem; /* Smaller font size for subheading */
      }
    
      .contact-form {
        padding: 3px; /* Reduce padding for very small screens */
      }
    
      .form-group input,
      .form-group textarea {
        font-size: 0.8rem; 
        width: 250px;
       /* Smaller font size for inputs */
      }
    
      .submit-button {
        font-size: 0.8rem;
        padding: 8px 14px; /* Adjust padding for very small screens */
   
      }

      
  }
  