.sap-mm-container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    max-width: 1500px;
    margin: 0 auto;
    background: url('../assets/sap.webp') no-repeat center center fixed;
    margin-top: -5rem;
    margin-bottom: 70%;
    background-size: cover;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* Cover the entire container */
}

.content-section,
.role-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: rgba(244, 244, 244, 0.8);
    /* Slightly transparent background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 70px 0;
}

.content-section h2,
.role-description h2 {
    font-size: 2.5em;
    color: black;
    margin-bottom: 10px;
    font-weight: bold;
}

.content-section p,
.role-description li {
    font-size: 1.2em;
    line-height: 1.6;
    margin: 10px 0;
    text-align: left;
}

.role-description h2 {
    margin-bottom: 20px;
}

.benefits-list,
.role-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.benefits-list li,
.role-list li {
    display: flex;
    align-items: center;
    font-size: 1.1em;
    margin: 10px 0;
}

.benefit-icon {
    font-size: 1.5em;
    color: #007bff;
    margin-right: 10px;
}

@media (max-width: 768px) {

    .content-section,
    .role-description {
        padding: 15px;
    }

    .content-section h2,
    .role-description h2 {
        font-size: 2em;
    }

    .content-section p,
    .role-description li {
        font-size: 1em;
    }
}

@media (max-width: 480px) {

    .content-section,
    .role-description {
        padding: 10px;
    }

    .content-section h2,
    .role-description h2 {
        font-size: 1.5em;
    }

    .content-section p,
    .role-description li {
        font-size: 0.9em;
    }
}

/* Roadmap Section Styles */

.roadmap-section {
    padding: 20px;
    background-color: rgba(233, 236, 239, 0.8);
    /* Slightly transparent background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.roadmap-section h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.roadmap-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.roadmap-card {
    flex: 1 1 calc(50% - 20px);
    background-color: #fff;
    border-radius: 50px;
    /* Rounded edges */
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* Enhanced shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    /* Position for potential pseudo-elements */
    overflow: hidden;
    /* Ensure content doesn't overflow */
    text-align: center;
    /* Center content */
}

.roadmap-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 120%;
    height: 120%;
    background-color: rgba(0, 123, 255, 0.1);
    /* Light blue background overlay */
    border-radius: 50%;
    /* Circular background */
    z-index: 8;
    /* Behind content */
}

.roadmap-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.roadmap-icon {
    font-size: 2.5em;
    /* Larger icon */
    color: #007bff;
    margin-bottom: 10px;
    z-index: 9;
    /* Above the pseudo-element */
}

.roadmap-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    z-index: 9;
    /* Above the pseudo-element */
}

.roadmap-card p {
    font-size: 1em;
    line-height: 1.6;
    z-index: 9;
    /* Above the pseudo-element */
}

/* Responsive styles */
@media (max-width: 768px) {
    .roadmap-card {
        flex: 1 1 100%;
    }
}

@media (max-width: 480px) {
    .roadmap-card {
        padding: 15px;
    }
}

@media (max-width: 480px) {

    .content-section,
    .role-description {
        padding: 10px;
    }

    .content-section h2,
    .role-description h2 {
        font-size: 1.5em;
    }

    .content-section p,
    .role-description li {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {

    .roadmap-section h2 {
        font-size: 1.8em;

    }
}


/* New Syllabus Section Styles */
/* New Styles for Syllabus Section */

.syllabus-section {
    background-color: rgba(244, 244, 244, 0.8);
    /* Slightly transparent background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 55px 0;
}

.syllabus-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.syllabus-section h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.syllabus-topic {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.syllabus-topic h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #007bff;
}

.syllabus-topic p {
    font-size: 1.1em;
    line-height: 1.5;
    margin: 0;
}

/* Responsive Styles for Syllabus Section */

@media (max-width: 768px) {
    .syllabus-content {
        flex-direction: column;
    }

    .syllabus-section h2 {
        font-size: 2em;

    }

    .syllabus-topic h3 {
        font-size: 1.2em;

    }
}

/* Why You Should Learn SAP MM Section Styles */

.why-learn-sapmm {
    padding: 20px;
    background-color: rgba(240, 240, 240, 0.8);
    /* Slightly transparent background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.why-learn-sapmm h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.why-learn-sapmm-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.why-learn-sapmm-item {
    flex: 1 1 calc(50% - 20px);
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.why-learn-sapmm-item h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #007bff;
}

.why-learn-sapmm-item p {
    font-size: 1em;
    line-height: 1.6;
    margin: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
    .why-learn-sapmm-item {
        flex: 1 1 100%;
    }
}

@media (max-width: 480px) {
    .why-learn-sapmm h2 {
        font-size: 1.8em;
    }

    .why-learn-sapmm-item h3 {
        font-size: 1.3em;
    }

    .why-learn-sapmm-item p {
        font-size: 0.9em;
    }
}


/* Batch Schedule Section Styles */

.batch-schedule {
    padding: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    /* Slightly transparent background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.batch-schedule h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.batch-schedule p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.schedule-table {
    width: 100%;
    border-collapse: collapse;
}

.schedule-table th,
.schedule-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.schedule-table th {
    background-color: #007bff;
    color: #fff;
    font-size: 1.2em;
}

.schedule-table td {
    background-color: #f9f9f9;
}

.enquire-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.enquire-link:hover {
    text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
    .batch-schedule p {
        font-size: 1em;
    }

    .schedule-table th,
    .schedule-table td {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .batch-schedule h2 {
        font-size: 1.8em;
    }

    .batch-schedule p {
        font-size: 0.9em;
    }

    .schedule-table th,
    .schedule-table td {
        font-size: 0.8em;
    }
}




/* Certification Section Styles */

.certification-section {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    /* Slightly transparent background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.certification-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.certification-text {
    flex: 1;
    padding: 20px;
}

.certification-text h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.certification-text p {
    font-size: 1.2em;
    line-height: 1.6;
    margin: 10px 0;
}

.certification-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease-out;
    /* Add fade-in animation */
}

.certification-img {
    max-width: 80%;
    /* Reduced size */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: zoomIn 1s ease-out;
    /* Add zoom-in animation */
}

/* Zoom-in animation */
@keyframes zoomIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Responsive styles */
@media (max-width: 768px) {
    .certification-content {
        flex-direction: column;
    }

    .certification-text h2 {
        font-size: 2em;
    }

    .certification-text p {
        font-size: 1em;
    }

    .certification-img {
        max-width: 60%;
        /* Smaller size for tablets */
    }
}

@media (max-width: 480px) {
    .certification-text {
        padding: 10px;
    }

    .certification-text h2 {
        font-size: 1.8em;
    }

    .certification-text p {
        font-size: 0.9em;
    }

    .certification-img {
        max-width: 80%;
        /* Smaller size for mobile devices */
    }
}


/* key insights css */
.key-insights-section {
    display: flex;
    justify-content: flex-start;
    /* Aligns content to the left */
    align-items: flex-start;
    /* Aligns items at the start of the container */
    padding: 20px;
    background-color: rgba(233, 236, 239, 0.8);
    /* Slightly transparent background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.key-insights-content {
    max-width: 1200px;
    width: 100%;
}

.key-insights-text {
    text-align: left;
}

.key-insights-text h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.key-insights-list ul {
    list-style: none;
    padding: 0;
}

.key-insights-list li {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    margin: 10px 0;
}

.benefit-icon {
    font-size: 1.5em;
    color: #007bff;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .key-insights-text h2 {
        font-size: 2em;
    }

    .key-insights-list li {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .key-insights-text h2 {
        font-size: 1.5em;
    }

    .key-insights-list li {
        font-size: 0.9em;
    }
}

/* alumini section */
.alumni-section {
    padding: 20px;
    background-color: rgba(233, 236, 239, 0.8);
    /* Slightly transparent background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    text-align: center;
}

.alumni-section h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

.alumni-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.alumni-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    /* Optional: to give rounded corners to the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: add a shadow for a more polished look */
}

@media (max-width: 768px) {
    .alumni-section h2 {
        font-size: 2em;
    }
}

@media (max-width: 480px) {
    .alumni-section h2 {
        font-size: 1.5em;
    }
}



/* FAQ Section Styles */
.faq-section {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.faq-section h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.faq {
    max-width: 800px;
    margin: 0 auto;
}

.faq-item {
    background-color: #fff;
    border-radius: 8px;
    margin: 10px 0;
    padding: 15px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-item.open .faq-answer {
    max-height: 200px;
    padding: 10px 0;
}

.faq-question {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.faq-answer p {
    margin: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
    .faq-section h2 {
        font-size: 2em;
    }

    .faq-item {
        padding: 10px 15px;
    }

    .faq-question {
        font-size: 1.1em;
    }
}

@media (max-width: 480px) {
    .faq-section h2 {
        font-size: 1.8em;
    }

    .faq-item {
        padding: 8px 10px;
    }

    .faq-question {
        font-size: 1em;
    }
}