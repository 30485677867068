.hero-section {
  height: 600px; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Prevent image from repeating */
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  color: white;
  text-align: center;
  margin-top: 0rem;
  background-image: url('../assets/bg.webp');
}

.hero-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
}

.hero-card {
  background-color: rgba(255, 255, 255, 0.4); /* Semi-transparent white background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%; /* Ensure the card takes full width available */
  color: black; /* Change text color inside the card */
  text-align: left; /* Align text to the left */
}

.hero-card h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-card p {
  font-size: 1.2rem;
  max-width: 100%;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    height: 400px; /* Reduce height on smaller screens */
    padding-left: 20px; /* Adjust padding for smaller screens */
  }

  .hero-card {
    max-width: 90%; /* Allow card to take more width */
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .hero-card h1 {
    font-size: 2.5rem; /* Adjust heading size */
  }

  .hero-card p {
    font-size: 1rem; /* Adjust paragraph size */
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 300px; /* Further reduce height on very small screens */
    padding-right: 20px; /* Adjust padding for very small screens */
  }

  .hero-card {
    max-width: 90%; /* Allow card to take more width */
    padding: 10px; /* Adjust padding for very small screens */
  }

  .hero-card h1 {
    font-size: 1.3rem; /* Adjust heading size */
  }

  .hero-card p {
    margin-top: -1rem;
    font-size: 0.8rem; /* Adjust paragraph size */
  }
}
