#footer {
  position: fixed;
  height: 85%; /* Make the footer full height of the viewport */
  width: 100vw;
  background: linear-gradient(to right, #1b3ac4, #042155);
  width: 100%;
  background: linear-gradient(to right, #000000, #130F40);
  color: #FFF;
  z-index: -1100;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.motive-color {
  position: absolute;
  top: -100px; /* Position slightly above the visible area */
  left: 80%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  width: 300px; /* Adjust width for the size of the blur */
  height: 300px; /* Adjust height for the size of the blur */
  background: rgba(0, 128, 255, 0.6); /* Blue color with transparency */
  filter: blur(100px); /* Apply blur */
  animation: pulse 1s infinite alternate; /* Animation for motion */
  z-index: -1099; /* Ensure it stays behind content but above the background */
}

/* Animation for motive color */
@keyframes pulse {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.2) translate(10px, 10px);
  }
}

.footer-section a{
  text-decoration: underline;
  color: #fff;
}

#footer .footer-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 2rem;
}

#footer h1 {
  font-size: 6rem;
  text-align: center;
  margin: 0; /* Remove margin */
  margin-top: auto; /* Automatically push h1 down */
  padding-bottom: 1rem; /* Add padding for spacing from footer bottom */
}
.footer-bottom-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-bottom-wrapper hr {
  width: 80%;
  border: 0;
  border-top: 2px solid #FFF;
  margin-bottom: 1rem;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 30px 30px; /* Add padding for spacing */
}

.footer-bottom .social-icons {
  display: flex;
  gap: 30px;
}

.footer-bottom .footer-bottom-text {
  font-size: 1rem;
}

.social-icons a {
  color: white;
  font-size: 1.5em;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ddd;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #footer h1 {
    font-size: 3rem;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom .footer-bottom-text {
    margin-top: 1rem;
  }
}

#footer {
  position: fixed;
  height: 90%;
  width: 100vw;
  background: linear-gradient(to right, #000000, #130F40);
  color: #FFF;
  z-index: -1100;
  bottom: 0;
  left: 0;
  display: flex;
  font-family: sans-serif;
  justify-content: center;
}

#footer h1 {
  bottom: 0;
  margin-top: 7rem;
  text-align: center;
  font-size: 8rem;

}

@media (max-width: 600px) {
  #footer {
    padding: 0.5rem;
    height: 51%;
    position: fixed;
    /* Adjust padding for smaller screens */
    width: 100vw;
    background: linear-gradient(to right, #000000, #130F40);
    color: #FFF;
    z-index: -1100;
    bottom: 0;
  }   
  .footer-bottom .footer-bottom-text {
    font-size: .8rem;
}
}

@media (max-width: 768px) {

  .footer-section{
    font-size: .7rem;
  }

  .motive-color {
   display: none;
  }

  #footer {
    position: fixed;
    height: auto;
    width: 100vw;
    background: linear-gradient(to right, #000000, #130F40);
    color: #FFF;
    z-index: -1100;
    bottom: 50;
    left: 0;
    display: flex;
    font-family: sans-serif;
    justify-content: center;
    height: 60vh;
  }
  #footer h1 {
    margin-top: 2rem;
    font-size:2rem;
  }
 .footer-bottom-text {
    font-size: .9rem;
}
}
