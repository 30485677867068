.sap-course {
    background: #EFEAE3;
    margin-bottom: 40%;
}

.animate-border img {
    position: absolute;
    height: 90%;
    width: 80%;
    margin-left: 1rem;
}

.left-info button a {
    color: white;
}

.banner-image {
    background: url('../assets/Hero1.webp') no-repeat;
    background-size: cover;
    height: 80vh;
    position: relative;
}

.left-info {
    position: absolute;
    top: 30%;
    left: 5%;
    width: 50%;
    padding: 2rem;
    color: white;
}

.left-info h1 {
    font-weight: bold;
    font-size: 3rem;
}

.left-info p {
    margin-top: 5px;
}

.left-info button {
    background: rgb(0, 145, 255);
    color: white;
    font-weight: bold;
    padding: .8rem;
    border: none;
    margin-top: 15px;
    border-radius: 5px;
}

.benefits-section {
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    background: #EFEAE3;
    z-index: 9;
    font-family: "Mukta", sans-serif;
}

.benefits-text {
    font-size: 2.2rem;
    width: 120%;
    padding: 2rem;
    text-align: start;
    font-family: sans-serif;
}

.benefits-text span {
    color: rgb(0, 110, 255);
    font-weight: 500;
}

.right-info {
    display: grid;
    padding-top: 5rem;
    margin: 0;
    padding: 2rem;
    justify-content: start;
}

.inside-info {
    display: grid;
    margin-top: 5rem;
    height: 80%;
    width: 80%;
}

.inside-info p {
    text-align: start;
    font-weight: 600;
    font-family: sans-serif;
}

.right-info img {
    height: 30vh;
    width: 25vw;
}

.sapcourse-animate {
    gap: 2rem;
    padding: 1rem;
    display: flex;
}

.animate-content {
    padding: 10px;
}

.animate-content h1 {
    font-size: 2.5rem;
    font-family: "Mukta", sans-serif;
}

.base-circle {
    position: fixed;
    width: 250px;
    height: 250px;
    left: 19%;
    top: 35%;
    background: rgb(6, 115, 193);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.animate-border {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 400px;
    margin-top: -10rem;
    border: 2px solid grey;
    border-radius: 50%;
    position: relative;
}

.circle-animate {
    margin-top: -3rem;
}

.list-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.column {
    flex: 1;
    padding: 10px;
}

.list-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.check-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.list-item span {
    font-size: 16px;
    color: #333;
}


.rotate-center {
    display: flex;
    justify-content: space-between;
}

.circle1 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ff6d43;
    animation: move 10s linear infinite;
}


@keyframes move {
    0% {
        transform: rotate(0deg) translateX(200px) rotate(0deg);
    }

    100% {
        transform: rotate(360deg) translateX(200px) rotate(-360deg);
    }
}

@media (max-width: 480px) {
    .sap-course {
        background: #EFEAE3;
        margin-bottom: 80%;
    }

    /* --2ndpage-- */
    .banner-image {
        background: url('https://media.istockphoto.com/id/1317323736/photo/a-view-up-into-the-trees-direction-sky.webp?s=612x612&w=0&k=20&c=i4HYO7xhao7CkGy7Zc_8XSNX_iqG0vAwNsrH1ERmw2Q=') no-repeat;
        background-size: cover;
        height: 55vh;
        position: relative;
        margin-top: -5rem;
    }

    .banner-image::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        /* Change the color and opacity as needed */
    }

    .left-info {
        top: 35%;
        left: 0;
        padding: 1rem;
        width: 80%;
        margin: 0;
    }

    .left-info h1 {
        font-size: 1.3rem;
    }

    .left-info p {
        font-size: 0.9rem;
    }

    .left-info button {
        padding: 0.5rem;
        font-size: 0.9rem;
    }

    .benefits-section {
        width: 100%;
        display: grid;
    }

    .benefits-text {
        margin: 1rem;
        font-size: 1rem;
        padding: 0;
        width: 60%;
    }

    .benefits-text span {
        padding-left: .5rem;
    }

    .inside-info {
        margin: 1rem;
    }

    .right-info {
        margin: 0;
        padding: 0;
    }

    .right-info img {
        height: 100%;
        width: 50%;
        margin: 0;
    }

    .right-info p {
        font-size: 0.8rem;
    }


    /* --1stPage-- */
    .sapcourse-animate {
        display: grid;
        justify-content: center;
        padding-left: 3rem;
    }

    .circle-animate {
        display: flex;
        margin-left: 3rem;
        margin-right: 0;
        margin-top: -4rem;
        margin-bottom: 5rem;
    }

    .base-circle {
        position: relative;
        width: 150px;
        height: 150px;
        left: 24.5%;
        top: 85%;
        background: rgb(6, 115, 193);
        border-radius: 50%;
        position: relative;
        overflow: hidden;
    }

    .animate-border img {
        position: absolute;
        height: 85%;
        width: 80%;
        margin-top: -1rem;
    }

    .animate-border {
        margin-left: 8px;
        margin-top: -15rem;
    }

    .animate-content {
        width: 100%;
        margin-top: 5rem;
    }

    .animate-content h1 {
        width: 65%;
        font-size: 1.3rem;
        margin-left: 3.5rem;
        font-family: "Mukta", sans-serif;
    }

    .list-container {
        text-shadow: none;
        width: 60%;
        margin-left: 45px;
        flex-direction: column;
    }

    .list-item span {
        font-weight: bold;
        text-shadow: none;
    }

    .column {
        padding: 0;
    }


    .animate-border {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
        width: 250px;
        border: 2px solid grey;
        border-radius: 50%;
        bottom: -210%;
        right: 27%;
    }

    .rotate-center {
        display: flex;
        justify-content: space-between;
    }

    .circle3 {
        width: 15px;
        height: 5px;
        border-radius: 50%;
        background-color: #ff6d43;
        left: 0.5%;
        top: 36%;
        animation: move 10s linear infinite;
    }

    @keyframes move {
        0% {
            transform: rotate(0deg) translateX(125px) rotate(0deg);
        }

        100% {
            transform: rotate(360deg) translateX(125px) rotate(-360deg);
        }
    }

}
.sap-modules { 
    text-align: center;
    padding: 50px 20px;
    background-color: #ffffff;
    background-image: url(../assets/bi.svg); /* Add your background image URL here */
    background-size: cover; /* Cover the entire section */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Avoid repeating the image */
    background-attachment: fixed; /* Keep the background image fixed */
    position: relative; /* Required for positioning overlay */
    color: #000000; /* Adjust text color for better contrast on the background */
  } 
  
  .sap-modules::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure the overlay is above the background image but below the content */
 } 
  
  .sap-modules h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .sap-modules p {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: rgb(255, 255, 255);
  }
  
  .modules-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Ensures 5 items per row */
    gap: 20px;
    margin-top: 30px;
  }
  
  .module {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .module:hover {
    transform: translateY(-10px);
  }
  
  .module img {
    max-width: 80px;
  }
  
  .module h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .module p {
    font-size: 1rem;
    color: #555;
  }
  
  .module a{
    border: 1px solid red;
    text-decoration: none;
    color: blue;
  }
  /* Make the grid responsive on smaller screens */
  @media (max-width: 1200px) {
    .modules-grid {
      grid-template-columns: repeat(4, 1fr); /* 4 columns for medium screens */
    }
  }
  
  @media (max-width: 992px) {
    .modules-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .modules-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
  }
  
  @media (max-width: 576px) {
    .modules-grid {
      grid-template-columns: 1fr; /* 1 column for mobile phones */
    }

    .sap-modules h2 {
        color: rgb(255, 255, 255);
        font-size: 1.5rem;
        margin-bottom: 20px;
      }

      .module img {
        max-width: 80px;
      }
      
      .module h3 {
        font-size: 1rem;
      }
      
      .module p {
        font-size: 1rem;
        color: #555;
      }
      .module {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;
      }
  }
  