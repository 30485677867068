.image-overlay-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
background-color: #fff;}

.image-card {
  position: relative;
  width: calc(25% - 20px); /* Four cards per row */
  transition: transform 0.3s;
}

.image-card:hover {
  transform: scale(1.05);
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity 0.3s;
}

.image-heading {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2em;
  z-index: 9;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 20px;
  text-align: center;
  box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
}

.image-wrapper.active .card-overlay {
  opacity: 1;
}

.card-overlay h3 {
  margin: 0;
  font-size: 1.5em;
}

.card-overlay p {
  margin: 10px 0 0;
  font-size: 1em;
}

@media (max-width: 1200px) {
  .image-card {
    width: calc(33.33% - 20px); /* Three cards per row */
  }
}

@media (max-width: 768px) {
  .image-card {
    width: calc(50% - 20px); /* Two cards per row */
  }
}

@media (max-width: 480px) {
  .image-card {
    width: 100%; /* Full width on small screens */
  }
}
