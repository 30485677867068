.global-modal-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
  }
  
  .global-modal-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 768px;
    margin: 16px;
    position: relative;
  }
  
  .global-modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    font-size: 1.25rem;
    color: gray;
    cursor: pointer;
  }
  
  .global-modal-close:hover {
    color: black;
  }
  
  .global-modal-content {
    max-height: 80vh;
    overflow-y: auto;
    padding: 16px;
  }
  